import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-173ba1f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapped-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebFrontHeader = _resolveComponent("WebFrontHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WebFrontHeader),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}