import { SmsEntryDto } from "@/dtos/SmsEntryDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { FileHelper } from "@/helpers/FileHelper";
import { SmsEntryModel } from "@/models/SmsEntryModel";
import { SmsEntryListViewModel, SmsEntryViewModel } from "@/viewModels/ViewModels";
import Axios, { AxiosResponse } from "axios";

export class SmsEntryApiController extends BaseApiController<SmsEntryModel, SmsEntryDto, SmsEntryViewModel, SmsEntryListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.SMS_ENTRIES);
  }

  public async importSmsEntries(file: File): Promise<AxiosResponse<SmsEntryListViewModel>> {
    const base64 = await FileHelper.fileToBase64(file);
    const base64dataOnly = base64.split(",")[1];
    return super._internalPost(`${this.apiEndpoint}/import`, {
      file: base64dataOnly,
    });
  }

  public async triggerProcessSmsEntriesJob(): Promise<AxiosResponse<string>> {
    return Axios.post(`${this.apiEndpoint}`, {});
  }
}
