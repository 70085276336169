/**
 * Helper class to evaluate responses from the API.
 */
export const FileHelper = {
  /* -------------------------------------------
  Converts an uploaded Blob file to base64 string.
  ------------------------------------------- */
  async fileToBase64(blobFile: File): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blobFile);
    });
  },
};
