<template>
  <WebFrontHeader></WebFrontHeader>
  <template v-if="!isTranslationsRestored">
    <ProgressSpinner></ProgressSpinner>
  </template>
  <template v-else>
    <div class="content">
      <router-view></router-view>
    </div>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import WebFrontHeader from "@/components/webfront/WebFrontHeader.vue";
import { useTranslationStore } from "@/stores/translationStore";

export default defineComponent({
  name: "WebFront",
  components: {
    WebFrontHeader,
  },
  setup() {
    const translationStore = useTranslationStore();

    const isTranslationsRestored = computed(() => translationStore.isTranslationsRestored);

    return {
      isTranslationsRestored,
    };
  },
});
</script>

<style scoped lang="scss">
.p-progress-spinner {
  margin: 20px auto;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 40px;

  :deep(a) {
    color: $primary-text-color;
  }

  :deep(form) {
    width: 80%;
    align-items: center;

    .form-row {
      max-width: 600px;

      span {
        input {
          &.p-component {
            width: 100%;
            background-color: $secondary-background-color;
          }
        }
      }
    }

    button {
      padding: 12px 48px;
      width: fit-content;
    }
  }
}
</style>
