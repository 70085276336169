<template>
  <div class="title-panel">
    <h2>Voucher Code einsehen</h2>
  </div>

  <VoucherCodesCreateEdit :id="id" :dto="dto"></VoucherCodesCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { VoucherCodeDto } from "@/dtos/VoucherCodeDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import VoucherCodesCreateEdit from "@/components/controlpanel/voucherCodes/VoucherCodesCreateEdit.vue";
import { VoucherCodeModel } from "@/models/VoucherCodeModel";
import { useVoucherCodeStore } from "@/stores/voucherCodeStore";

/**
 * View that renders the the "edit" form for voucherCodes.
 */
export default defineComponent({
  name: "VoucherCodesEdit",
  setup() {
    const store = useVoucherCodeStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/voucher-codes");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new VoucherCodeDto(new VoucherCodeModel()));

    // get entity from store.
    onMounted(() => {
      store.getVoucherCode(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { VoucherCodesCreateEdit },
});
</script>

<style scoped lang="scss"></style>
