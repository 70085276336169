import { Constants } from "@/enums/Constants";
import { AxiosResponse } from "axios";
import { ApiControllerCore } from "./ApiControllerCore";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";

export class ReportingApiController extends ApiControllerCore {
  protected apiEndpoint: string;

  constructor() {
    super();

    this.apiEndpoint = Constants.ApiEndpoints.REPORTING;
  }

  public async getVoucherCodesReport(): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/vouchercodes`);
  }

  public async getSmsEntriesReport(): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/smsentries`);
  }

  public async getSecurityReport(): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/security`);
  }

  public async getSmsReport(): Promise<AxiosResponse<ReportingViewModel>> {
    return super._internalGet(`${this.apiEndpoint}/sms`);
  }
}
