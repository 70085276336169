import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw, RouterScrollBehavior } from "vue-router";
import { useUserStatusStore } from "@/stores/userStatusStore";
import { useTranslationStore } from "@/stores/translationStore";
import { LanguageHelper } from "@/helpers/LanguageHelper";

import WebFront from "@/views/WebFront.vue";
import WebFrontLandingPage from "@/views/webfront/LandingPage.vue";
import WebFrontPageNotFound from "@/views/webfront/PageNotFound.vue";

import ControlPanel from "@/views/ControlPanel.vue";
import ControlPanelDashboard from "@/views/controlpanel/Dashboard.vue";
import ControlPanelAdministration from "@/views/controlpanel/Administration.vue";
import ControlPanelTranslations from "@/views/controlpanel/translations/Translations.vue";
import ControlPanelTranslationsList from "@/views/controlpanel/translations/TranslationsList.vue";
import ControlPanelTranslationsEdit from "@/views/controlpanel/translations/TranslationsEdit.vue";
import ControlPanelSmsEntries from "@/views/controlpanel/smsEntries/SmsEntries.vue";
import ControlPanelSmsEntriesList from "@/views/controlpanel/smsEntries/SmsEntriesList.vue";
import ControlPanelSmsEntriesEdit from "@/views/controlpanel/smsEntries/SmsEntriesEdit.vue";
import ControlPanelVoucherCodes from "@/views/controlpanel/voucherCodes/VoucherCodes.vue";
import ControlPanelVoucherCodesList from "@/views/controlpanel/voucherCodes/VoucherCodesList.vue";
import ControlPanelVoucherCodesEdit from "@/views/controlpanel/voucherCodes/VoucherCodesEdit.vue";
import ControlPanelLogEntries from "@/views/controlpanel/logEntries/LogEntries.vue";
import ControlPanelLogEntriesList from "@/views/controlpanel/logEntries/LogEntriesList.vue";
import ControlPanelLogEntriesEdit from "@/views/controlpanel/logEntries/LogEntriesEdit.vue";

import WrappedPanel from "@/views/shared/WrappedPanel.vue";
import Login from "@/views/shared/Login.vue";

/**
 * Defines all frontend routes and routing behaviours.
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "WebFront",
    component: WebFront,
    children: [
      {
        name: "WebFrontLandingPage",
        path: "",
        component: WebFrontLandingPage,
      },
      {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: WebFrontPageNotFound,
      },
    ],
  },
  {
    path: "/",
    name: "WrappedPanel",
    component: WrappedPanel,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
    ],
  },
  {
    path: "/controlpanel",
    name: "ControlPanel",
    component: ControlPanel,
    meta: {
      requireAdminPermission: true,
    },
    children: [
      {
        path: "",
        name: "ControlPanelDashboard",
        meta: { requireAdminPermission: true },
        component: ControlPanelDashboard,
      },
      {
        path: "admin",
        name: "ControlPanelAdministration",
        meta: { requireAdminPermission: true },
        component: ControlPanelAdministration,
      },
      {
        path: "translations",
        name: "ControlPanelTranslations",
        meta: { requireAdminPermission: true },
        component: ControlPanelTranslations,
        children: [
          {
            path: "",
            name: "ControlPanelTranslationsList",
            meta: { requireAdminPermission: true },
            component: ControlPanelTranslationsList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelTranslationsEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelTranslationsEdit,
          },
        ],
      },
      {
        path: "sms-entries",
        name: "ControlPanelSmsEntries",
        meta: { requireAdminPermission: true },
        component: ControlPanelSmsEntries,
        children: [
          {
            path: "",
            name: "ControlPanelSmsEntriesList",
            meta: { requireAdminPermission: true },
            component: ControlPanelSmsEntriesList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelSmsEntriesEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelSmsEntriesEdit,
          },
        ],
      },
      {
        path: "voucher-codes",
        name: "ControlPanelVoucherCodes",
        meta: { requireAdminPermission: true },
        component: ControlPanelVoucherCodes,
        children: [
          {
            path: "",
            name: "ControlPanelVoucherCodesList",
            meta: { requireAdminPermission: true },
            component: ControlPanelVoucherCodesList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelVoucherCodesEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelVoucherCodesEdit,
          },
        ],
      },
      {
        path: "log-entries",
        name: "ControlPanelLogEntries",
        meta: { requireAdminPermission: true },
        component: ControlPanelLogEntries,
        children: [
          {
            path: "",
            name: "ControlPanelLogEntriesList",
            meta: { requireAdminPermission: true },
            component: ControlPanelLogEntriesList,
          },
          {
            path: "edit/:id",
            name: "ControlPanelLogEntriesEdit",
            meta: { requiresAdminPermission: true },
            component: ControlPanelLogEntriesEdit,
          },
        ],
      },
    ],
  },
];

// return to top of page when navigating.
const scrollBehavior: RouterScrollBehavior = (to: any, from: any, savedPosition: any) => {
  if (savedPosition) return savedPosition;
  if (to.hash) return { selector: to.hash };

  return { left: 0, top: 0 };
};

// create router component.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: scrollBehavior,
});

// check if user is authenticated for routes that require authentication.
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStatusStore = useUserStatusStore();
  const translationStore = useTranslationStore();

  // first get user, if not already fetched.
  await userStatusStore.getUserStatus();

  // update store front language if necessary.
  LanguageHelper.updateFrontendLanguage();

  // then get translations, if not already fetched.
  if (!translationStore.isTranslationsRestored) {
    await translationStore.getActiveTranslations();
  }

  if (to.matched.some((record) => record.meta.requireAdminPermission) && !userStatusStore.isAdmin) {
    // redirect to /login if requireAdminPermission is true.
    next("/login");
  } else if (to.matched.some((record) => record.meta.requireUserPermission) && !userStatusStore.isAuthenticated) {
    // redirect to landing page.
    next("/");
  } else {
    // continue as normal if not.
    next();
  }
});

export default router;
