import { ExportType } from "./ExportType";

export const Constants = {
  ApiEndpoints: {
    USERS: "/users",
    USER_STATUS: "/users/status",
    TRANSLATIONS: "/translations",
    LOG_ENTRIES: "/logentries",
    PARTICIPATIONS: "/participations",
    FEEDBACKS: "/feedbacks",
    REPORTING: "/reporting",
    SMS_ENTRIES: "/smsentries",
    VOUCHER_CODES: "/vouchercodes",
  },
  StatusCodes: {
    SUCCESS: 200,
  },
  MimeTypes: [
    {
      key: ExportType.PDF,
      mimeType: "application/pdf",
    },
    {
      key: ExportType.EXCEL,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  ],
  Regexes: {
    PHONE_NUMBER: /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/,
  },
  Breakpoints: {
    mobile: 860,
    tablet: 1440,
    desktop: 1920,
  },
};
