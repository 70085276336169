import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { SmsEntryApiController } from "@/api/SmsEntryApiController";
import { SmsEntryListDto } from "@/dtos/SmsEntryDtos";
import { SmsEntryDto } from "@/dtos/SmsEntryDtos";
import { SmsEntryListViewModel, SmsEntryViewModel } from "@/viewModels/ViewModels";
import { SmsEntryModel } from "@/models/SmsEntryModel";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";
import { SmsEntryStatus } from "@/enums/SmsEntryStatus";
import { FilterMatchMode } from "primevue/api";
import { ObjectHelper } from "@/helpers/ObjectHelper";
import { AxiosResponse } from "axios";

const apiController = new SmsEntryApiController();
const storeApiProxy = new StoreApiProxy<SmsEntryModel, SmsEntryDto, SmsEntryListDto, SmsEntryViewModel, SmsEntryListViewModel>(apiController, SmsEntryListDto.mappings, SmsEntryDto.mappings);

export const useSmsEntryStore = defineStore("smsEntry", {
  state: () => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    typeFilter: SmsEntryStatus.NONE,
  }),actions: {
    /* -------------------------------------------
     * Get all smsEntries from API.
     * -------------------------------------------- */
    async getSmsEntries(listDto: SmsEntryListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single smsEntry from API.
     * -------------------------------------------- */
    async getSmsEntry(dto: SmsEntryDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Triggers the ProcessSmsEntriesJob manually.
     * -------------------------------------------- */
    async triggerProcessSmsEntriesJob(): Promise<AxiosResponse<string>> {
      return apiController.triggerProcessSmsEntriesJob();
    },
    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: SmsEntryListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async importSmsEntries(listDto: SmsEntryListDto, file: File): Promise<void> {
      listDto.isLoading = true;
      listDto.hasError = false;
  
      return new Promise<void>((resolve, reject) => {
        return (apiController as SmsEntryApiController)
          .importSmsEntries(file)
          .then((success) => {
            ObjectHelper.copyExistingPropsFromTo(success.data, listDto, SmsEntryListDto.mappings);
            resolve();
          })
          .catch((error) => {
            listDto.hasError = true;
            reject(error);
          })
          .finally(() => {
            listDto.isLoading = false;
          });
      });
    },

    // ===== Mutations =====

    setSortColumn(listDto: SmsEntryListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: SmsEntryListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: SmsEntryListDto, page: number): void {
      listDto.page = page;
    },

    setFilter(listDto: SmsEntryListDto, filter: string | null, typeFilter: number): void {
      listDto.filter = filter;
      listDto.typeFilter = typeFilter;
    },
  },
});
