import { VoucherCodeDto } from "@/dtos/VoucherCodeDtos";
import { BaseApiController } from "./BaseApiController";
import { Constants } from "@/enums/Constants";
import { VoucherCodeModel } from "@/models/VoucherCodeModel";
import { VoucherCodeListViewModel, VoucherCodeViewModel } from "@/viewModels/ViewModels";

export class VoucherCodeApiController extends BaseApiController<VoucherCodeModel, VoucherCodeDto, VoucherCodeViewModel, VoucherCodeListViewModel> {
  constructor() {
    super(Constants.ApiEndpoints.VOUCHER_CODES);
  }
}
