import { ModelDto } from "./base/ModelDto";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { SmsEntryModel } from "@/models/SmsEntryModel";

class SmsEntryDto extends ModelDto<SmsEntryModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.SmsEntryModel(x),
    },
  ];
}
class SmsEntryListDto extends ListDto<SmsEntryDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.SmsEntryDto(y)),
    },
  ];
}

export { SmsEntryDto, SmsEntryListDto };
