<template>
  <h1>Dashboard</h1>

  <h2 class="mt-0">SMS-Einträge</h2>
  <div class="statistics" v-if="!isSmsEntriesLoading">
    <Card class="w-25">
      <template #content>
        <strong> <i class="pi pi-users"></i>Total</strong>
        <p>{{ smsEntriesReport.totalSmsEntriesCount }}</p>
      </template>
    </Card>
    <Card class="w-25 warning">
      <template #content>
        <strong> <i class="pi pi-users"></i>In Warteschlange</strong>
        <p>{{ smsEntriesReport.queuedSmsEntriesCount }}</p>
      </template>
    </Card>
    <Card class="w-25 success">
      <template #content>
        <strong> <i class="pi pi-users"></i>Erfolgreich versendet</strong>
        <p>{{ smsEntriesReport.sentSmsEntriesCount }}</p>
      </template>
    </Card>
    <Card class="w-25 error">
      <template #content>
        <strong> <i class="pi pi-users"></i>Fehlgeschlagen</strong>
        <p>{{ smsEntriesReport.failedSmsEntriesCount }}</p>
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>

  <h2>TWINT Voucher-Codes</h2>
  <div class="statistics" v-if="!isVoucherCodesLoading">
    <Card class="w-33 vouchers">
      <template #content>
        <strong> <i class="pi pi-users"></i>Total</strong>
        <p>{{ voucherCodesReport.totalVoucherCodesCount }}</p>
      </template>
    </Card>
    <Card class="w-33">
      <template #content>
        <strong> <i class="pi pi-users"></i>Unverarbeitet / Verbleibend</strong>
        <p>{{ voucherCodesReport.unusedVoucherCodesCount }}</p>
      </template>
    </Card>
    <Card class="w-33 success">
      <template #content>
        <strong> <i class="pi pi-users"></i>Erfolgreich versendet</strong>
        <p>{{ voucherCodesReport.sentVoucherCodesCount }}</p>
      </template>
    </Card>

    <Card class="w-50 content-center">
      <template #content>
        <strong> <i class="pi pi-gift"></i>Voucher-Codes Verteilung</strong>
        <Chart v-if="pieChartOptions" type="pie" :data="voucherCodesDistributionChartData" :options="pieChartOptions" class="box" />
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>

  <h2>SMS-Versand</h2>
  <div class="statistics" v-if="!isSmsLoading">
    <Card class="w-50 success">
      <template #content>
        <strong> <i class="pi pi-users"></i>Erfolgreich versendete SMS</strong>
        <p>{{ smsReport.amountOfSuccessfulSmsRequests }}</p>
      </template>
    </Card>
    <Card class="w-50 error">
      <template #content>
        <strong> <i class="pi pi-users"></i>Fehlgeschlagene SMS</strong>
        <p>{{ smsReport.amountOfFailedSmsRequests }}</p>
      </template>
    </Card>
    <Card class="w-50 content-center">
      <template #content>
        <strong> <i class="pi pi-gift"></i>SMS-Versand</strong>
        <Chart v-if="pieChartOptions" type="pie" :data="smsDistributionChartData" :options="pieChartOptions" class="box" />
      </template>
    </Card>
  </div>
  <Skeleton v-else height="200px"></Skeleton>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useReportingStore } from "@/stores/reportingStore";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { AxiosResponse } from "axios";
import { SmsReport } from "@/dtos/data/reports/SmsReport";
import { SmsEntriesReport } from "@/dtos/data/reports/SmsEntriesReport";
import { VoucherCodesReport } from "@/dtos/data/reports/VoucherCodesReport";
import Skeleton from "primevue/skeleton";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "Dashboard",
  components: {
    Skeleton,
  },
  setup() {
    const reportingStore = useReportingStore();

    const chartOptions = ref();
    const pieChartOptions = ref();

    const smsDistributionChartData = ref();
    const voucherCodesDistributionChartData = ref();

    const smsReport = ref<InstanceType<typeof SmsReport>>(new SmsReport());
    const smsEntriesReport = ref<InstanceType<typeof SmsEntriesReport>>(new SmsEntriesReport());
    const voucherCodesReport = ref<InstanceType<typeof VoucherCodesReport>>(new VoucherCodesReport());

    const { isSmsLoading, isSmsEntriesLoading, isVoucherCodesLoading } = storeToRefs(reportingStore);

    // make initial API calls.
    onMounted(() => {
      chartOptions.value = setChartOptions();
      pieChartOptions.value = setPieChartOptions();

      // get voucher codes reporting data.
      reportingStore.getVoucherCodesReport().then((result: AxiosResponse<ReportingViewModel>) => {
        voucherCodesReport.value = result.data.voucherCodesReport;
        voucherCodesDistributionChartData.value = setVoucherCodesDistributionChartData();
      });
      // get sms entries reporting data.
      reportingStore.getSmsEntriesReport().then((result: AxiosResponse<ReportingViewModel>) => {
        smsEntriesReport.value = result.data.smsEntriesReport;
      });

      // get sms reporting data.
      reportingStore.getSmsReport().then((result: AxiosResponse<ReportingViewModel>) => {
        smsReport.value = result.data.smsReport;
        smsDistributionChartData.value = setSmsDistributionChartData();
      });
    });

    // =========== CHARTS ======================
    const setChartOptions = () => {
      // no specific chart options.
      return {};
    };
    const setPieChartOptions = () => {
      return {
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
      };
    };

    // SMS CHARTS
    const setSmsDistributionChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: ["Erfolgreiche SMS", "Fehlgeschlagene SMS"],
        datasets: [
          {
            label: "Anzahl",
            data: [smsReport.value.amountOfSuccessfulSmsRequests, smsReport.value.amountOfFailedSmsRequests],
            backgroundColor: [documentStyle.getPropertyValue("--green-400"), documentStyle.getPropertyValue("--red-200")],
          },
        ],
      };
    };

    // VOUCHER CODES CHARTS
    const setVoucherCodesDistributionChartData = () => {
      const documentStyle = getComputedStyle(document.body);
      return {
        labels: ["Verwendete Voucher-Codes", "Übrige Voucher-Codes"],
        datasets: [
          {
            label: "Anzahl",
            data: [voucherCodesReport.value.sentVoucherCodesCount, voucherCodesReport.value.unusedVoucherCodesCount],
            backgroundColor: [documentStyle.getPropertyValue("--green-400"), documentStyle.getPropertyValue("--gray-400")],
          },
        ],
      };
    };

    return {
      isSmsEntriesLoading,
      isVoucherCodesLoading,
      isSmsLoading,
      smsReport,
      smsEntriesReport,
      voucherCodesReport,
      chartOptions,
      pieChartOptions,
      smsDistributionChartData,
      voucherCodesDistributionChartData,
    };
  },
});
</script>

<style scoped lang="scss">
h2 {
  margin-top: 100px;
}
.statistics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 20px;

  .success p {
    color: var(--green-500);
  }
  .warning p {
    color: var(--orange-500);
  }
  .error p {
    color: var(--red-500);
  }

  > div {
    &.w-100 {
      width: 100%;
    }
    &.w-50 {
      width: calc(50% - 10px);
    }
    &.w-33 {
      width: calc(33% - 13px);
    }
    &.w-25 {
      width: calc(25% - 15px);
    }

    :deep(.p-card-content) {
      padding: 0;
    }

    i {
      margin-right: 10px;
    }

    p {
      margin: 5px 0;
      font-size: $font-size-large;
      color: $secondary-color;
    }

    :deep(.p-card-body) {
      canvas {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .p-chart {
      height: 20rem;
    }
  }
}
</style>
