import { defineStore } from "pinia";
import { SortDirection } from "@/enums/SortDirection";
import { VoucherCodeApiController } from "@/api/VoucherCodeApiController";
import { VoucherCodeListDto } from "@/dtos/VoucherCodeDtos";
import { VoucherCodeDto } from "@/dtos/VoucherCodeDtos";
import { VoucherCodeListViewModel, VoucherCodeViewModel } from "@/viewModels/ViewModels";
import { VoucherCodeModel } from "@/models/VoucherCodeModel";
import { StoreApiProxy } from "@/helpers/StoreApiProxy";
import stringMixins from "@/mixins/stringMixins";
import { ExportType } from "@/enums/ExportType";
import { FilterMatchMode } from "primevue/api";
import { VoucherCodeStatus } from "@/enums/VoucherCodeStatus";

const apiController = new VoucherCodeApiController();
const storeApiProxy = new StoreApiProxy<VoucherCodeModel, VoucherCodeDto, VoucherCodeListDto, VoucherCodeViewModel, VoucherCodeListViewModel>(apiController, VoucherCodeListDto.mappings, VoucherCodeDto.mappings);

export const useVoucherCodeStore = defineStore("voucherCode", {
  state: () => ({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    typeFilter: VoucherCodeStatus.NONE,
  }),
  actions: {
    /* -------------------------------------------
     * Get all voucherCodes from API.
     * -------------------------------------------- */
    async getVoucherCodes(listDto: VoucherCodeListDto): Promise<void> {
      return storeApiProxy.apiGetMultiple(listDto);
    },

    /* -------------------------------------------
     * Get single voucherCode from API.
     * -------------------------------------------- */
    async getVoucherCode(dto: VoucherCodeDto, id: number): Promise<void> {
      return storeApiProxy.apiGetSingle(dto, id);
    },

    /* -------------------------------------------
     * Saves one voucherCode DTO.
     * -------------------------------------------- */
    async updateVoucherCode(dto: VoucherCodeDto): Promise<void> {
      return storeApiProxy.apiUpdate(dto);
    },

    /* -------------------------------------------
     * Creates a new voucherCode DTO.
     * -------------------------------------------- */
    async createVoucherCode(dto: VoucherCodeDto): Promise<void> {
      return storeApiProxy.apiCreate(dto);
    },

    /* -------------------------------------------
     * Deletes an existing voucherCode DTO.
     * -------------------------------------------- */
    async deleteVoucherCode(dto: VoucherCodeDto): Promise<void> {
      return storeApiProxy.apiDelete(dto);
    },

    /* -------------------------------------------
     * Exports all entities as PDF or XLSX.
     * -------------------------------------------- */
    async exportList(listDto: VoucherCodeListDto, exportType: ExportType): Promise<void> {
      return storeApiProxy.apiExport(listDto, exportType);
    },

    // ===== Mutations =====

    setSortColumn(listDto: VoucherCodeListDto, column: string): void {
      listDto.sortColumn = stringMixins.stripModelString(column);
    },

    setSortDirection(listDto: VoucherCodeListDto, direction: SortDirection): void {
      listDto.sortDirection = direction;
    },

    setPage(listDto: VoucherCodeListDto, page: number): void {
      listDto.page = page;
    },

    setFilter(listDto: VoucherCodeListDto, filter: string | null, typeFilter: number): void {
      listDto.filter = filter;
      listDto.typeFilter = typeFilter;
    },
  },
});
