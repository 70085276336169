import { ReportingApiController } from "@/api/ReportingApiController";
import { ReportingViewModel } from "@/viewModels/ReportingViewModel";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

const apiController = new ReportingApiController();

export const useReportingStore = defineStore("reporting", {
  state() {
    return { 
      isSecurityLoading: false,
      isSmsLoading: false,
      isVoucherCodesLoading: false,
      isSmsEntriesLoading: false,
     };
  },
  actions: {
    /* -------------------------------------------
     * Get all Voucher Codes Reportings from API.
     * -------------------------------------------- */
    async getVoucherCodesReport(): Promise<AxiosResponse<ReportingViewModel>> {
      this.isVoucherCodesLoading = true;

      return apiController.getVoucherCodesReport().finally(() => {
        this.isVoucherCodesLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all SMS entries Reportings from API.
     * -------------------------------------------- */
    async getSmsEntriesReport(): Promise<AxiosResponse<ReportingViewModel>> {
      this.isSmsEntriesLoading = true;

      return apiController.getSmsEntriesReport().finally(() => {
        this.isSmsEntriesLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all Security Reportings from API.
     * -------------------------------------------- */
    async getSecurityReport(): Promise<AxiosResponse<ReportingViewModel>> {
      this.isSecurityLoading = true;

      return apiController.getSecurityReport().finally(() => {
        this.isSecurityLoading = false;
      });
    },

    /* -------------------------------------------
     * Get all SMS Reportings from API.
     * -------------------------------------------- */
    async getSmsReport(): Promise<AxiosResponse<ReportingViewModel>> {
      this.isSmsLoading = true;

      return apiController.getSmsReport().finally(() => {
        this.isSmsLoading = false;
      });
    },
  },
});
