<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="">
      <Panel header="Voucher Code Informationen">
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-gift"></i>
            <InputText type="text" id="sentTo" :disabled="true" v-model="form.code" />
            <label for="code">Code</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-exclamation-circle"></i>
            <Dropdown v-model="form.voucherCodeStatus" :options="statusOptions" :disabled="true" option-value="value">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>{{ statusOptions.find((o) => o.value == slotProps.value)?.text }}</div>
                </div>
                <div v-else>&nbsp;</div>
              </template>
              <template #option="slotProps">
                <div v-if="slotProps.option">{{ slotProps.option.text }}</div>
                <div v-else>&nbsp;</div>
              </template>
            </Dropdown>
            <label for="status">Status</label>
          </span>
        </div>

        <Tag v-if="form.voucherCodeStatus === VoucherCodeStatus.UNUSED" :severity="'warning'">Unverarbeitet</Tag>
        <Tag v-else-if="form.voucherCodeStatus === VoucherCodeStatus.SENT" :severity="'success'">Versendet</Tag>
      </Panel>

      <Panel header="Versandkontrolle" v-if="dto.model.voucherCodeStatus === VoucherCodeStatus.SENT">
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar id="sentAt" :disabled="true" date-format="dd.mm.yy" showTime hourFormat="24" v-model="dto.model.sentAt" />
            <label for="sentAt">Versendet am</label>
          </span>
        </div>

        <div class="form-row" v-if="dto.model.smsEntries.length === 1">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-send"></i>
            <InputText type="text" id="sentTo" :disabled="true" v-model="dto.model.smsEntries" :value="`${dto.model.smsEntries[0].phoneNumber} (${dto.model.smsEntries[0].firstname} ${dto.model.smsEntries[0].surname})`"/>
            <label for="sentTo">Versendet an</label>
          </span>
        </div>
      </Panel>
    </form>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { VoucherCodeFormData } from "@/dtos/data/VoucherCodeFormData";
import { VoucherCodeDto } from "@/dtos/VoucherCodeDtos";
import { FormHelper } from "@/helpers/FormHelper";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { VoucherCodeStatus } from "@/enums/VoucherCodeStatus";
import { DropdownOption } from "@/helpers/EnumHelper";

/**
 * A shared component used for editing (and creating) voucherCodes.
 */
export default defineComponent({
  name: "VoucherCodesCreateEdit",
  props: {
    id: Number,
    dto: {
      type: VoucherCodeDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  setup(props) {
    const formHelper = new FormHelper("/controlpanel/voucher-codes");

    // get entity from props.
    const { id, dto } = toRefs(props);

    const statusOptions = [
      {
        text: "Unverarbeitet",
        value: VoucherCodeStatus.UNUSED,
      },
      {
        text: "Versendet",
        value: VoucherCodeStatus.SENT,
      },
    ] as DropdownOption<VoucherCodeStatus>[];

    // set up as form state as reactive object.
    const form = ref(new VoucherCodeFormData(dto.value));

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new VoucherCodeFormData(newValue)));

    return {
      id,
      dto,
      form,
      formHelper,
      statusOptions,
      VoucherCodeStatus,
    };
  },
});
</script>

<style scoped lang="scss">
.p-panel {
  margin-bottom: 40px;
}

:deep(.p-panel-content) {
  padding-top: 30px;
}

:disabled,
.p-disabled,
.p-calendar-disabled,
:deep(.p-calendar-disabled) input {
  opacity: 0.8 !important;
}

i {
  z-index: 1;
}
</style>
