<template>
  <div class="title-panel">
    <h2>Log-Einträge</h2>
    <div class="flex align-items-end justify-content-end gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel" @click="onExportExcel"></Button>
      <Button type="button" severity="info" icon="pi pi-file-pdf" label="PDF" @click="onExportPdf"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :totalRecords="totalDtosCount"
    :loading="isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToEdit($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />
        </span>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine Log-Einträge gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>

    <Column filter field="model.logEntryType" header="Typ">
      <template #body="slotProps">
        {{ LogEntryType[slotProps.data.model.logEntryType] }}
      </template>
    </Column>
    <Column filter field="model.logEntryMessageType" header="Sub-Typ">
      <template #body="slotProps">
        {{ LogEntryMessageType[slotProps.data.model.logEntryMessageType] }}
      </template>
    </Column>
    <Column sortable filter field="model.message" header="Text"></Column>
    <Column sortable filter field="model.requestOrigin" header="Request Origin"></Column>
    <Column sortable filter field="model.requestIpAddressV4" header="Request IP-Adresse"></Column>
    <Column sortable filter field="model.createdAt" header="Erstellt am">
      <template #body="slotProps">
        <DateDisplay :date="slotProps.data.model.createdAt" :withTime="true"></DateDisplay>
      </template>
    </Column>
    <Column header="Aktionen" header-style="direction:rtl;" body-style="text-align:right">
      <template #body="slotProps">
        <Button label="Details" icon="pi pi-eye" severity="secondary" @click="goToEdit(slotProps.data.model.id)"></Button>
      </template>
    </Column>

    <template #footer> Es gibt {{ totalDtosCount }} Log-Einträge. </template>
  </DataTable>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { FilterMatchMode } from "primevue/api";
import { SortDirection } from "@/enums/SortDirection";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { useLogEntryStore } from "@/stores/logEntryStore";
import { LogEntryListDto } from "@/dtos/LogEntryDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { LogEntryType } from "@/enums/LogEntryType";
import { LogEntryMessageType } from "@/enums/LogEntryMessageType";
import { ExportType } from "@/enums/ExportType";

/**
 * View that renders a list of all logEntries.
 */
export default defineComponent({
  name: "LogEntriesList",
  data: function () {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  components: { DateDisplay },
  methods: {
    async onPage($event: DataTablePageEvent) {
      const store = useLogEntryStore();

      store.setPage(this.listDto, $event.page + 1);

      await store.getLogEntries(this.listDto);
    },

    async onSort($event: DataTableSortEvent) {
      const store = useLogEntryStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getLogEntries(this.listDto);
    },

    async onRefresh() {
      const store = useLogEntryStore();
      await store.getLogEntries(this.listDto);
    },

    async onFilter() {
      const store = useLogEntryStore();

      store.setFilter(this.listDto, this.filters.global.value);

      await store.getLogEntries(this.listDto);
    },

    onExportPdf(): void {
      const store = useLogEntryStore();

      store.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const store = useLogEntryStore();

      store.exportList(this.listDto, ExportType.EXCEL);
    },

    goToEdit(id: string) {
      this.$router.push("/controlpanel/log-entries/edit/" + id);
    },
  },
  setup() {
    const store = useLogEntryStore();

    const listDto = reactive(new LogEntryListDto([]));

    const totalDtosCount = computed(() => listDto.totalRecordCount);
    const isLoading = computed(() => listDto.isLoading);

    // make initial fetch.
    onMounted(() => {
      store.getLogEntries(listDto);
    });

    return {
      listDto,
      totalDtosCount,
      isLoading,
      LogEntryType,
      LogEntryMessageType,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-datatable-header > div) {
  margin-bottom: 5px;
}

img {
  max-height: 50px;
  max-width: 50px;
}
</style>
