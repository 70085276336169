import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { IVoucherCodeModel } from "./interfaces/IVoucherCodeModel";
import { VoucherCodeStatus } from "@/enums/VoucherCodeStatus";
import { SmsEntryModel } from "./SmsEntryModel";
import { TypeHelper } from "@/helpers/TypeHelper";

export class VoucherCodeModel extends BaseModel implements IVoucherCodeModel {
  code: string;
  voucherCodeStatus: VoucherCodeStatus;
  sentAt?: Date | null;
  smsEntries:  SmsEntryModel[];
  
  constructor() {
    super();

    this.code = "";
    this.voucherCodeStatus = VoucherCodeStatus.NONE;
    this.sentAt = null;
    this.smsEntries = [];
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "sentAt",
      cast: (x) => TypeHelper.mappers.Date(x),
    },
  ];
}
