import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { TranslationModel } from "@/models/TranslationModel";
import { TranslationDto } from "../TranslationDtos";
import { ITranslationModel } from "@/models/interfaces/ITranslationModel";
import { toRaw } from "vue";

export class TranslationFormData extends BaseFormData<ITranslationModel> implements ITranslationModel {
  public translationKey: string;
  public languageDe: string;
  public languageFr: string;
  public languageIt: string;

  public originalData: TranslationModel;

  constructor(dto: TranslationDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.translationKey = dto.model.translationKey;
    this.languageDe = dto.model.languageDe;
    this.languageFr = dto.model.languageFr;
    this.languageIt = dto.model.languageIt;

    this.originalData = structuredClone(toRaw(dto.model));
    
    this.formSchema = Yup.object({
      translationKey: Yup.string().required(),
      languageDe: Yup.string().required(),
      languageFr: Yup.string().required(),
      languageIt: Yup.string().required(),
    });
  }
}
