import { ModelDto } from "./base/ModelDto";
import { TypeMapping } from "./shared/TypeMapping";
import { TypeHelper } from "@/helpers/TypeHelper";
import { ListDto } from "./base/ListDto";
import { VoucherCodeModel } from "@/models/VoucherCodeModel";

class VoucherCodeDto extends ModelDto<VoucherCodeModel> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "model",
      cast: (x) => TypeHelper.mappers.VoucherCodeModel(x),
    },
  ];
}
class VoucherCodeListDto extends ListDto<VoucherCodeDto> {
  public static readonly mappings: TypeMapping[] = [
    {
      key: "list",
      cast: (x) => x.map((y: any) => TypeHelper.mappers.VoucherCodeDto(y)),
    },
  ];
}

export { VoucherCodeDto, VoucherCodeListDto };
