<template>
  <div class="title-panel">
    <h2>Voucher Codes</h2>
    <div class="flex align-items-end justify-content-end gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel" @click="onExportExcel"></Button>
      <Button type="button" severity="info" icon="pi pi-file-pdf" label="PDF" @click="onExportPdf"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :totalRecords="totalDtosCount"
    :loading="isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToEdit($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2" v-if="filters !== undefined">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />&nbsp;
          <Dropdown v-model="typeFilter" :options="statusOptions" option-value="value" @change="onFilter" placeholder="Nach Status filtern...">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ statusOptions.find((o) => o.value == slotProps.value)?.text }}</div>
              </div>
              <div v-else>kein Filter</div>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option">{{ slotProps.option.text }}</div>
              <div v-else>&nbsp;</div>
            </template>
          </Dropdown>
        </span>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine Voucher Codes gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>

    <Column sortable filter field="model.code" header="Text"></Column>
    <Column sortable filter field="model.voucherCodeStatus" header="Status">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.model.voucherCodeStatus === VoucherCodeStatus.UNUSED" :severity="'warning'">Unverarbeitet</Tag>
        <Tag v-else-if="slotProps.data.model.voucherCodeStatus === VoucherCodeStatus.SENT" :severity="'success'">Versendet</Tag>
      </template>
    </Column>
    <Column sortable filter field="model.sentAt" header="Versendet am">
      <template #body="slotProps">
        <DateDisplay :date="slotProps.data.model.sentAt" :withTime="true" v-if="slotProps.data.model.voucherCodeStatus === VoucherCodeStatus.SENT"></DateDisplay>
        <span v-else>-</span>
      </template>
    </Column>
    <Column header="Aktionen" header-style="direction:rtl;" body-style="text-align:right">
      <template #body="slotProps">
        <Button label="Details" icon="pi pi-eye" severity="secondary" @click="goToEdit(slotProps.data.model.id)"></Button>
      </template>
    </Column>

    <template #footer> Es gibt {{ totalDtosCount }} Voucher Codes. </template>
  </DataTable>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { FilterMatchMode } from "primevue/api";
import { SortDirection } from "@/enums/SortDirection";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { useVoucherCodeStore } from "@/stores/voucherCodeStore";
import { VoucherCodeListDto } from "@/dtos/VoucherCodeDtos";
import DateDisplay from "@/components/shared/DateDisplay.vue";
import { VoucherCodeStatus } from "@/enums/VoucherCodeStatus";
import { ExportType } from "@/enums/ExportType";
import { DropdownOption } from "@/helpers/EnumHelper";
import { storeToRefs } from "pinia";

/**
 * View that renders a list of all voucherCodes.
 */
export default defineComponent({
  name: "VoucherCodesList",
  data: function () {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  components: { DateDisplay },
  methods: {
    async onPage($event: DataTablePageEvent) {
      const store = useVoucherCodeStore();

      store.setPage(this.listDto, $event.page + 1);

      await store.getVoucherCodes(this.listDto);
    },

    async onSort($event: DataTableSortEvent) {
      const store = useVoucherCodeStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getVoucherCodes(this.listDto);
    },

    async onRefresh() {
      const store = useVoucherCodeStore();
      await store.getVoucherCodes(this.listDto);
    },

    async onFilter() {
      const store = useVoucherCodeStore();

      store.setFilter(this.listDto, this.filters.global.value, this.typeFilter);

      await store.getVoucherCodes(this.listDto);
    },

    onExportPdf(): void {
      const store = useVoucherCodeStore();

      store.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const store = useVoucherCodeStore();

      store.exportList(this.listDto, ExportType.EXCEL);
    },

    goToEdit(id: string) {
      this.$router.push("/controlpanel/voucher-codes/edit/" + id);
    },
  },
  setup() {
    const store = useVoucherCodeStore();

    const { filters, typeFilter } = storeToRefs(store);

    const listDto = ref(new VoucherCodeListDto([]));

    const totalDtosCount = computed(() => listDto.value.totalRecordCount);
    const isLoading = computed(() => listDto.value.isLoading);

    const statusOptions = [
      {
        text: "kein Filter",
        value: VoucherCodeStatus.NONE,
      },
      {
        text: "Unverarbeitet",
        value: VoucherCodeStatus.UNUSED,
      },
      {
        text: "Versendet",
        value: VoucherCodeStatus.SENT,
      },
    ] as DropdownOption<VoucherCodeStatus>[];

    // make initial fetch.
    onMounted(() => {
      store.setFilter(listDto.value, filters.value.global.value, typeFilter.value);

      store.getVoucherCodes(listDto.value);
    });

    return {
      listDto,
      filters,
      typeFilter,
      totalDtosCount,
      isLoading,
      statusOptions,
      VoucherCodeStatus,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-datatable-header > div) {
  margin-bottom: 5px;
}

img {
  max-height: 50px;
  max-width: 50px;
}
</style>
