<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="">
      <Panel header="System-Informationen">
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar date-format="dd.mm.yy" showTime hourFormat="24" id="createdAt" :disabled="true" v-model="dto.model.createdAt" />
            <label for="createdAt">Erstellt am</label>
          </span>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar date-format="dd.mm.yy" showTime hourFormat="24" id="updatedAt" :disabled="true" v-model="dto.model.updatedAt" />
            <label for="updatedAt">Zuletzt aktualisiert am</label>
          </span>
        </div>
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="createdBy" :disabled="true" v-model="dto.model.createdBy.displayName" />
            <label for="createdBy">Erstellt von</label>
          </span>

          <span class="p-float-label p-input-icon-left" v-if="dto.model.updatedBy">
            <i class="pi pi-user"></i>
            <InputText type="text" id="updatedBy" :disabled="true" v-model="dto.model.updatedBy.displayName" />
            <label for="updatedBy">Zuletzt aktualisiert von</label>
          </span>
          <span v-else></span>
        </div>
      </Panel>

      <Panel header="Personalien">
        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-phone"></i>
            <InputText type="text" id="phoneNumber" :disabled="true" v-model="form.phoneNumber" />
            <label for="phoneNumber">Handynummer</label>
          </span>
        </div>

        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="firstname" :disabled="true" v-model="form.firstname" />
            <label for="firstname">Vorname</label>
          </span>
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <InputText type="text" id="surname" :disabled="true" v-model="form.surname" />
            <label for="surname">Nachname</label>
          </span>
        </div>

        <div class="form-row">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-exclamation-circle"></i>
            <Dropdown v-model="form.smsEntryStatus" :options="statusOptions" :disabled="true" option-value="value">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <div>{{ statusOptions.find((o) => o.value == slotProps.value)?.text }}</div>
                </div>
                <div v-else>&nbsp;</div>
              </template>
              <template #option="slotProps">
                <div v-if="slotProps.option">{{ slotProps.option.text }}</div>
                <div v-else>&nbsp;</div>
              </template>
            </Dropdown>
            <label for="status">Status</label>
          </span>
        </div>

        <Tag v-if="form.smsEntryStatus === SmsEntryStatus.QUEUED" :severity="'warning'">In Warteschlange</Tag>
        <Tag v-else-if="form.smsEntryStatus === SmsEntryStatus.SENT" :severity="'success'">Versendet</Tag>
        <Tag v-else-if="form.smsEntryStatus === SmsEntryStatus.FAILED" :severity="'error'">Fehlgeschlagen</Tag>
      </Panel>

      <Panel header="Voucher Code Informationen" v-if="form.smsEntryStatus === SmsEntryStatus.SENT && dto.model.usedVoucherCode !== null && dto.model.usedVoucherCode !== undefined">
        <div class="form-row split">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-phone"></i>
            <InputText type="text" id="usedVoucherCode" :disabled="true" v-model="dto.model.usedVoucherCode.code" />
            <label for="usedVoucherCode">Erhaltener Voucher Code</label>
          </span>

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-calendar"></i>
            <Calendar date-format="dd.mm.yy" showTime hourFormat="24" id="sentAt" :disabled="true" v-model="dto.model.usedVoucherCode.sentAt" />
            <label for="sentAt">Versendet am</label>
          </span>
        </div>

        <Tag v-if="dto.model.usedVoucherCode.voucherCodeStatus === VoucherCodeStatus.UNUSED" :severity="'info'">Unverarbeitet</Tag>
        <Tag v-else-if="dto.model.usedVoucherCode.voucherCodeStatus === VoucherCodeStatus.SENT" :severity="'success'">Versendet</Tag>
      </Panel>
    </form>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <Toast>
    <template #message="slotProps">
      <div class="controlpanel-toast">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { SmsEntryFormData } from "@/dtos/data/SmsEntryFormData";
import { SmsEntryDto } from "@/dtos/SmsEntryDtos";
import { useToast } from "primevue/usetoast";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { ToastMessageOptions } from "primevue/toast";
import { ISmsEntryModel } from "@/models/interfaces/ISmsEntryModel";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { SmsEntryStatus } from "@/enums/SmsEntryStatus";
import { DropdownOption } from "@/helpers/EnumHelper";
import { VoucherCodeStatus } from "@/enums/VoucherCodeStatus";

/**
 * A shared component used for editing (and creating) smsEntries.
 */
export default defineComponent({
  name: "SmsEntriesCreateEdit",
  props: {
    id: Number,
    dto: {
      type: SmsEntryDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  methods: {},
  setup(props) {
    const toast = useToast();

    const validator = new Validator<ISmsEntryModel>();
    const formHelper = new FormHelper("/controlpanel/sms-entries");

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new SmsEntryFormData(dto.value));

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new SmsEntryFormData(newValue)));

    const statusOptions = [
      {
        text: "kein Filter",
        value: SmsEntryStatus.NONE,
      },
      {
        text: "In Warteschlange",
        value: SmsEntryStatus.QUEUED,
      },
      {
        text: "Versendet",
        value: SmsEntryStatus.SENT,
      },
      {
        text: "Fehlgeschlagen",
        value: SmsEntryStatus.FAILED,
      },
    ] as DropdownOption<SmsEntryStatus>[];

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      id,
      dto,
      form,
      formHelper,
      validator,
      showToast,
      statusOptions,
      VoucherCodeStatus,
      SmsEntryStatus,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-panel-content) {
  padding-top: 30px;
}
</style>
