<template>
  <nav>
    <CustomMenuBar :items="generalItems" :is-exact-active-class="true" :is-active-class="false"></CustomMenuBar>
    <h3>Navigation</h3>
    <CustomMenuBar :items="navigationItems" :is-exact-active-class="true" :is-active-class="false"></CustomMenuBar>
    <h3>Verwaltung</h3>
    <CustomMenuBar :items="managementItems" :is-exact-active-class="false" :is-active-class="true"></CustomMenuBar>
    <h3>System</h3>
    <CustomMenuBar :items="evaluationItems" :is-exact-active-class="false" :is-active-class="true"></CustomMenuBar>
  </nav>
</template>

<script lang="ts">
import { MenuItem } from "primevue/menuitem";
import { defineComponent, ref } from "vue";
import CustomMenuBar from "@/components/shared/CustomMenuBar.vue";

/**
 * Component used to render the navigation items in the Control Panel.
 */
export default defineComponent({
  name: "ControlPanelNavigation",
  components: {
    CustomMenuBar,
  },
  setup() {
    const generalItems = ref([
      {
        label: "Zur Hauptseite",
        icon: "pi pi-external-link",
        url: "/",
        isExact: true,
      },
    ] as MenuItem[]);

    const navigationItems = ref([
      {
        label: "Dashboard",
        icon: "pi pi-home",
        url: "/controlpanel",
        isExact: true,
      },
    ] as MenuItem[]);

    const managementItems = ref([
      {
        label: "SMS-Einträge",
        icon: "pi pi-send",
        url: "/controlpanel/sms-entries",
        isExact: false,
      },
      {
        label: "TWINT Voucher Codes",
        icon: "pi pi-gift",
        url: "/controlpanel/voucher-codes",
        isExact: false,
      },
      {
        label: "Übersetzungen",
        icon: "pi pi-language",
        url: "/controlpanel/translations",
        isExact: false,
      },
    ] as MenuItem[]);

    const evaluationItems = ref([
      {
        label: "Administration",
        icon: "pi pi-cog",
        url: "/controlpanel/admin",
        isExact: false,
      },
      {
        label: "Log-Einträge",
        icon: "pi pi-server",
        url: "/controlpanel/log-entries",
        isExact: false,
      },
    ] as MenuItem[]);

    return {
      generalItems,
      navigationItems,
      managementItems,
      evaluationItems,
    };
  },
});
</script>

<style scoped lang="scss">
nav {
  position: fixed;
  top: 100px;
  left: 0;
  min-width: 250px;
  height: 100%;
  background-color: $secondary-background-color;
  display: flex;
  flex-direction: column;
  padding: 10px;

  h3 {
    margin: 5px 0;
    color: $secondary-color;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  nav {
    position: relative;
    top: 0;
  }
}
</style>
