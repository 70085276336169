<template>
  <h1>Galbani TWINT</h1>
  <Button v-if="isAuthenticated" label="Zum Control Panel" @click="goToControlPanel"></Button>
  <Button v-else label="Login" @click="goToLogin"></Button>
</template>

<script lang="ts">
import { Translator } from "@/helpers/Translator";
import { computed, defineComponent, ref } from "vue";
import Translate from "@/components/shared/Translate.vue";
import { ToastMessageOptions } from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useUserStatusStore } from "@/stores/userStatusStore";

export default defineComponent({
  name: "WebFrontLandingPage",
  components: {
    Translate,
  },
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    goToControlPanel() {
      this.$router.push("/controlpanel");
    }
  },
  setup() {
    const toast = useToast();
    const userStatusStore = useUserStatusStore();

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    const isAuthenticated = computed(() => userStatusStore.isAuthenticated);

    return {
      Translator,
      showToast,
      isAuthenticated,
    };
  },
});
</script>

<style scoped lang="scss">
.mt-10 {
  margin-top: 100px;
}

form {
  margin-top: 10px;
}
</style>
