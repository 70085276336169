import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { SmsEntryModel } from "@/models/SmsEntryModel";
import { SmsEntryDto } from "../SmsEntryDtos";
import { ISmsEntryModel } from "@/models/interfaces/ISmsEntryModel";
import { toRaw } from "vue";
import { Language } from "@/enums/Language";
import { SmsEntryStatus } from "@/enums/SmsEntryStatus";

export class SmsEntryFormData extends BaseFormData<ISmsEntryModel> implements ISmsEntryModel {
  public phoneNumber: string;
  public surname: string;
  public firstname: string;
  public language: Language;
  public smsEntryStatus: SmsEntryStatus;
  public usedVoucherCodeId?: number | null;

  public originalData: SmsEntryModel;

  constructor(dto: SmsEntryDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.phoneNumber = dto.model.phoneNumber;
    this.surname = dto.model.surname;
    this.firstname = dto.model.firstname;
    this.language = dto.model.language;
    this.smsEntryStatus = dto.model.smsEntryStatus;
    this.usedVoucherCodeId = dto.model.usedVoucherCodeId;

    this.originalData = structuredClone(toRaw(dto.model));

    this.formSchema = Yup.object({
      phoneNumber: Yup.string().required(),
      surname: Yup.string().required(),
      firstname: Yup.string().required(),
      language: Yup.mixed<Language>().required(),
      smsEntryStatus: Yup.mixed<SmsEntryStatus>().required(),
      usedVoucherCodeId: Yup.number().optional().nullable(),
    });
  }
}
