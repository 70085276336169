import { Language } from "@/enums/Language";
import { useTranslationStore } from "@/stores/translationStore";

export const Translator = {
  translate(text: string): string {
    const translationStore = useTranslationStore();

    const translation = translationStore.listDto.list.find((t) => t.model.translationKey === text);
    if (translation) {
      switch (translationStore.currentLanguage) {
        case Language.DE:
          return translation.model.languageDe;
        case Language.FR:
          return translation.model.languageFr;
        case Language.IT:
          return translation.model.languageIt;
      }
    }

    return text;
  },
};
