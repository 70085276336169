import { TypeMapping } from "@/dtos/shared/TypeMapping";
import { BaseModel } from "./BaseModel";
import { ISmsEntryModel } from "./interfaces/ISmsEntryModel";
import { UserModel } from "./UserModel";
import { VoucherCodeModel } from "./VoucherCodeModel";
import { SmsEntryStatus } from "@/enums/SmsEntryStatus";
import { Language } from "@/enums/Language";
import { TypeHelper } from "@/helpers/TypeHelper";

export class SmsEntryModel extends BaseModel implements ISmsEntryModel {
  phoneNumber: string;
  surname: string;
  firstname: string;
  language: Language;
  smsEntryStatus: SmsEntryStatus;
  usedVoucherCodeId?: number | null;
  usedVoucherCode?: VoucherCodeModel | null;

  createdById: number;
  createdBy: UserModel;
  updatedById?: number | null;
  updatedBy?: UserModel | null;

  constructor() {
    super();

    this.phoneNumber = "";
    this.surname = "";
    this.firstname = "";
    this.language = Language.NONE;
    this.smsEntryStatus = SmsEntryStatus.NONE;
    this.usedVoucherCodeId = null;
    this.usedVoucherCode = null;

    this.createdById = -1;
    this.createdBy = new UserModel();
    this.updatedById = null;
    this.updatedBy = null;
  }

  public static readonly mappings: TypeMapping[] = [
    {
      key: "usedVoucherCode",
      cast: (x) => TypeHelper.mappers.VoucherCodeModel(x),
    },
  ];
}
