<template>
    <div class="title-panel">
    <h2>SMS-Eintrag bearbeiten</h2>
  </div>

  <SmsEntriesCreateEdit :id="id" :dto="dto"></SmsEntriesCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { SmsEntryDto } from "@/dtos/SmsEntryDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import SmsEntriesCreateEdit from "@/components/controlpanel/smsEntries/SmsEntriesCreateEdit.vue";
import { SmsEntryModel } from "@/models/SmsEntryModel";
import { useSmsEntryStore } from "@/stores/smsEntryStore";

/**
 * View that renders the the "edit" form for smsEntries.
 */
export default defineComponent({
  name: "SmsEntriesEdit",
  setup() {
    const store = useSmsEntryStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/smsEntries");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new SmsEntryDto(new SmsEntryModel()));

    // get entity from store.
    onMounted(() => {
      store.getSmsEntry(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { SmsEntriesCreateEdit },
});
</script>

<style scoped lang="scss"></style>
