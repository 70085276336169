<template>
  <div v-if="form.isReady()">
    <form @submit.prevent="" @change="validator.validateForm(form as TranslationFormData, dto)">
      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-book"></i>
          <InputText type="text" id="translationKey" :disabled="true" v-model="form.translationKey" />
          <label for="translationKey">Key</label>
        </span>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-language"></i>
          <InputText type="text" id="languageDe" :disabled="dto.isLoading" v-model="form.languageDe" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'languageDe') }" />
          <label for="languageDe">Übersetzung Deutsch</label>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'languageDe')">Übersetzung Itanlienisch ist ein Pflichtfeld.</small>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-language"></i>
          <InputText type="text" id="languageFr" :disabled="dto.isLoading" v-model="form.languageFr" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'languageFr') }" />
          <label for="languageFr">Übersetzung Französisch</label>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'languageFr')">Übersetzung Französisch ist ein Pflichtfeld.</small>
      </div>

      <div class="form-row">
        <span class="p-float-label p-input-icon-left">
          <i class="pi pi-language"></i>
          <InputText type="text" id="languageIt" :disabled="dto.isLoading" v-model="form.languageIt" :class="{ 'p-invalid': validator.hasValidationErrors(dto, 'languageIt') }" />
          <label for="languageIt">Übersetzung Italienisch</label>
        </span>
        <small class="p-error" v-show="validator.hasValidationErrors(dto, 'languageIt')">Übersetzung Itanlienisch ist ein Pflichtfeld.</small>
      </div>
    </form>

    <div class="button-panel">
      <div></div>
      <div>
        <Button type="button" label="Abbrechen" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
        <Button type="submit" label="Speichern" icon="pi pi-save" @click="onSave()" :disabled="!form.isValidForm()" :loading="dto.isLoading"></Button>
      </div>
    </div>
  </div>

  <div v-else-if="dto.isLoading">
    <LoadingSkeleton></LoadingSkeleton>
  </div>

  <div v-else>
    <Message severity="warn" :closable="false">Es existiert keine Entität mit Id '{{ id }}'.</Message>
    <div>
      <Button type="button" label="Zurück zur Liste" :severity="'secondary'" @click="formHelper.returnToList()"></Button>
    </div>
  </div>

  <Toast>
    <template #message="slotProps">
      <div class="controlpanel-toast">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <Button class="confirm-button" :size="'small'" label="Zurück zur Liste" :severity="'success'" @click="formHelper.returnToList()"></Button>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";
import { useTranslationStore } from "@/stores/translationStore";
import { TranslationFormData } from "@/dtos/data/TranslationFormData";
import { TranslationDto } from "@/dtos/TranslationDtos";
import { useToast } from "primevue/usetoast";
import { FormHelper } from "@/helpers/FormHelper";
import { Validator } from "@/helpers/Validator";
import { ToastMessageOptions } from "primevue/toast";
import { ITranslationModel } from "@/models/interfaces/ITranslationModel";
import LoadingSkeleton from "@/components/shared/LoadingSkeleton.vue";
import { ObjectHelper } from "@/helpers/ObjectHelper";

/**
 * A shared component used for editing (and creating) translations.
 */
export default defineComponent({
  name: "TranslationsCreateEdit",
  props: {
    id: Number,
    dto: {
      type: TranslationDto,
      required: true,
    },
  },
  components: { LoadingSkeleton },
  methods: {
    async onSave() {
      const store = useTranslationStore();

      this.form.shouldValidate = true;

      // update values in store.
      ObjectHelper.copyExistingPropsFromTo(this.form, this.dto.model);

      // validate before save.
      this.validator.validateForm(this.form as TranslationFormData, this.dto).then(() => {
        if (this.form.isExistingOrNew()) {
          // send PUT request and show toast if successful.
          store.updateTranslation(this.dto as TranslationDto).then(async () => {
            // refresh list and return to list.
            await store.getTranslations(store.listDto);

            this.showToast({
              life: 3000,
              severity: "success",
              summary: "Erfolgreich gespeichert",
              detail: "Die Übersetzung wurde erfolgreich aktualisiert.",
            });
          });
        }
      });
    },
  },
  setup(props) {
    const toast = useToast();

    const validator = new Validator<ITranslationModel>();
    const formHelper = new FormHelper("/controlpanel/translations");

    // get entity from props.
    const { id, dto } = toRefs(props);

    // set up as form state as reactive object.
    const form = ref(new TranslationFormData(dto.value));

    // setup form watchers.
    watch(dto.value, (newValue) => (form.value = new TranslationFormData(newValue)));

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      id,
      dto,
      form,
      formHelper,
      validator,
      showToast,
    };
  },
});
</script>

<style scoped lang="scss"></style>
