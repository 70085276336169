import * as Yup from "yup";
import { BaseFormData } from "./BaseFormData";
import { VoucherCodeModel } from "@/models/VoucherCodeModel";
import { VoucherCodeDto } from "../VoucherCodeDtos";
import { IVoucherCodeModel } from "@/models/interfaces/IVoucherCodeModel";
import { toRaw } from "vue";
import { VoucherCodeStatus } from "@/enums/VoucherCodeStatus";

export class VoucherCodeFormData extends BaseFormData<IVoucherCodeModel> implements IVoucherCodeModel {
  public code: string;
  public voucherCodeStatus: VoucherCodeStatus;
  public sentAt?: Date | null;

  public originalData: VoucherCodeModel;

  constructor(dto: VoucherCodeDto) {
    super(dto);

    if (dto.model.id) {
      this.isInitializedProperly = true;

      if (dto.model.id === -1) {
        this.isInitializedWithNewEntity = true;
      }
    }

    this.code = dto.model.code;
    this.voucherCodeStatus = dto.model.voucherCodeStatus;
    this.sentAt = dto.model.sentAt;

    this.originalData = structuredClone(toRaw(dto.model));
    
    this.formSchema = Yup.object({
      code: Yup.string().required(),
      voucherCodeStatus: Yup.mixed<VoucherCodeStatus>().required(),
      sentAt: Yup.date().optional().nullable(),
    });
  }
}
