<template>
  <h1>Administration</h1>

  <Message severity="error" :closable="false">Achtung: Dieser Bereich ist ausschliesslich für Sonderfälle (z.B. falls der SMS-Versand-Job nicht ausgeführt wurde). Hier nur Änderungen vornehmen, wenn absolut notwendig.</Message>

  <Button label="SMS-Einträge in Warteschlange jetzt verschicken" severity="secondary" @click="onSendQueuedSmsEntriesNow"></Button>

  <Panel header="Response" v-if="response != ''">
    {{ response }}
  </Panel>

  <ConfirmDialog></ConfirmDialog>
</template>

<script lang="ts">
import { FormHelper } from "@/helpers/FormHelper";
import { useSmsEntryStore } from "@/stores/smsEntryStore";
import { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "Administration",
  components: {},
  methods: {
    onSendQueuedSmsEntriesNow(e: Event) {
      this.formHelper.prompt(
        e,
        "Wirklich alle SMS-Einträge in Warteschlange jetzt verarbeiten?",
        "Achtung",
        "Ja",
        "Abbrechen",
        "pi pi-step-forward",
        () => {
          setTimeout(() => {
            this.formHelper.prompt(
              e,
              "Bitte die Aktion erneut bestätigen:",
              "Achtung",
              "Bestätigen",
              "Abbrechen",
              "pi pi-step-forward",
              () => {
                const store = useSmsEntryStore();
                store.triggerProcessSmsEntriesJob().then((r: AxiosResponse<string>) => {
                  this.response = r.data;
                });
              },
              () => {}
            );
          }, 0);
        },
        () => {}
      );
    },
  },
  setup() {
    const formHelper = new FormHelper("/admin");

    const response = ref("");

    return {
      formHelper,
      response,
    };
  },
});
</script>

<style scoped lang="scss">
.p-message {
  border: 5px solid $primary-color;
  color: $primary-color;
}

.p-panel {
  white-space: pre;
}
</style>
