<template>
  <h2>SMS-Einträge</h2>
  <div class="title-panel">
    <Button type="button" severity="success" icon="pi pi-plus" label="Importieren" @click="onImportClicked"></Button>

    <div class="flex align-items-end justify-space-between gap-2">
      <Button type="button" severity="info" icon="pi pi-file-excel" label="Excel" @click="onExportExcel"></Button>
      <Button type="button" severity="info" icon="pi pi-file-pdf" label="PDF" @click="onExportPdf"></Button>
    </div>
  </div>

  <DataTable
    paginator
    lazy
    :value="listDto.list"
    v-model:filters="filters"
    :rows="10"
    :totalRecords="totalDtosCount"
    :loading="isLoading"
    @page="onPage($event)"
    @sort="onSort($event)"
    @row-dblclick="goToEdit($event.data.model.id)"
    dataKey="model.id"
    filterDisplay="menu"
    size="small"
    stripedRows
    tableStyle="min-width: 50rem"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2" v-if="filters !== undefined">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText v-debounce:300ms="onFilter" v-model="filters['global'].value" placeholder="Suche..." />&nbsp;
          <Dropdown v-model="typeFilter" :options="statusOptions" option-value="value" @change="onFilter" placeholder="Nach Status filtern...">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ statusOptions.find((o) => o.value == slotProps.value)?.text }}</div>
              </div>
              <div v-else>kein Filter</div>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option">{{ slotProps.option.text }}</div>
              <div v-else>&nbsp;</div>
            </template>
          </Dropdown>
        </span>
        <Button icon="pi pi-refresh" rounded raised @click="onRefresh()"></Button>
      </div>
    </template>
    <template #empty> Keine SMS-Einträge gefunden. </template>
    <template #loading> <ProgressSpinner strokeWidth="2" /> </template>

    <Column sortable filter field="model.smsEntryStatus" header="Handynummer">
      <template #body="slotProps">
        <Tag v-if="slotProps.data.model.smsEntryStatus === SmsEntryStatus.QUEUED" :severity="'warning'">In Warteschlange</Tag>
        <Tag v-else-if="slotProps.data.model.smsEntryStatus === SmsEntryStatus.SENT" :severity="'success'">Versendet</Tag>
        <Tag v-else-if="slotProps.data.model.smsEntryStatus === SmsEntryStatus.FAILED" :severity="'error'">Fehlgeschlagen</Tag>
      </template>
    </Column>
    <Column sortable filter field="model.phoneNumber" header="Handynummer"></Column>
    <Column sortable filter field="model.firstname" header="Vorname"></Column>
    <Column sortable filter field="model.surname" header="Nachname"></Column>
    <Column sortable filter field="model.language" header="Sprache">
      <template #body="slotProps">
        {{ Language[slotProps.data.model.language] }}
      </template>
    </Column>
    <Column sortable filter field="model.usedVoucherCode.codeId" header="Verwendeter Voucher Code">
      <template #body="slotProps">
        {{ slotProps.data.model.usedVoucherCode ? slotProps.data.model.usedVoucherCode.code : "-" }}
      </template>
    </Column>
    <Column header="Aktionen" header-style="direction:rtl;" body-style="text-align:right">
      <template #body="slotProps">
        <Button label="Details" icon="pi pi-eye" severity="secondary" @click="goToEdit(slotProps.data.model.id)"></Button>
      </template>
    </Column>
    <template #footer> Es gibt {{ totalDtosCount }} SMS-Einträge. </template>
  </DataTable>

  <Toast>
    <template #message="slotProps">
      <div class="controlpanel-toast">
        <div class="p-toast-summary">{{ slotProps.message.summary }}</div>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { FilterMatchMode } from "primevue/api";
import { SortDirection } from "@/enums/SortDirection";
import { useSmsEntryStore } from "@/stores/smsEntryStore";
import { DataTablePageEvent, DataTableSortEvent } from "primevue/datatable";
import { ExportType } from "@/enums/ExportType";
import { SmsEntryListDto } from "@/dtos/SmsEntryDtos";
import { storeToRefs } from "pinia";
import { SmsEntryStatus } from "@/enums/SmsEntryStatus";
import { DropdownOption } from "@/helpers/EnumHelper";
import { useFileDialog } from "@vueuse/core";
import { useToast } from "primevue/usetoast";
import { ToastMessageOptions } from "primevue/toast";
import { Language } from "@/enums/Language";
import { SmsEntryListViewModel } from "@/viewModels/ViewModels";
import { AxiosResponse } from "axios";
import { StatusCode } from "@/enums/StatusCode";

/**
 * View that renders a list of all smsEntries.
 */
export default defineComponent({
  name: "SmsEntriesList",
  data: function () {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sujet: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  methods: {
    async onPage($event: DataTablePageEvent) {
      const store = useSmsEntryStore();

      store.setPage(this.listDto, $event.page + 1);

      await store.getSmsEntries(this.listDto);
    },
    async onSort($event: DataTableSortEvent) {
      const store = useSmsEntryStore();

      store.setSortColumn(this.listDto, $event.sortField as string);
      store.setSortDirection(this.listDto, $event.sortOrder === 1 ? SortDirection.ASC : SortDirection.DESC);

      await store.getSmsEntries(this.listDto);
    },

    async onRefresh() {
      const store = useSmsEntryStore();
      store.getSmsEntries(this.listDto);
    },

    async onFilter() {
      const store = useSmsEntryStore();

      store.setFilter(this.listDto, this.filters.global.value, this.typeFilter);

      await store.getSmsEntries(this.listDto);
    },

    onExportPdf(): void {
      const store = useSmsEntryStore();

      store.exportList(this.listDto, ExportType.PDF);
    },

    onExportExcel(): void {
      const store = useSmsEntryStore();

      store.exportList(this.listDto, ExportType.EXCEL);
    },

    onImportClicked(): void {
      const store = useSmsEntryStore();

      const { files, open } = useFileDialog({
        accept: "text/csv",
      });
      open();

      watch(files, (files: FileList | null) => {
        if (files === null) {
          this.showToast({
            life: 3000,
            severity: "error",
            summary: "Fehlgeschlagen",
            detail: "Es wurde keine gültige Datei ausgewählt.",
          });
          return;
        }

        if (files.length !== 1) {
          this.showToast({
            life: 3000,
            severity: "error",
            summary: "Fehlgeschlagen",
            detail: "Bitte wählen Sie nur eine Datei auf einmal aus.",
          });
          return;
        }

        const count = this.listDto.list.length;

        // import, then fetch again, then show new entries.
        store
          .importSmsEntries(this.listDto, files[0])
          .catch((error: AxiosResponse<SmsEntryListViewModel>) => {
            return new Promise<void>((resolve, reject) => {
              switch (error.data.statusCode) {
                case StatusCode.EXCEL_IMPORT_INVALID_COLUMN_COUNT:
                  this.showToast({
                    life: 5000,
                    severity: "error",
                    summary: "Fehlgeschlagen",
                    detail: "Die Excel-Datei enthält eine ungültige Anzahl von Spalten. Es wurden keine Einträge erstellt.",
                  });
                  break;
                case StatusCode.EXCEL_IMPORT_INVALID_PHONE_NUMBER:
                  this.showToast({
                    life: 5000,
                    severity: "error",
                    summary: "Fehlgeschlagen",
                    detail: "Die Excel-Datei enthält Einträge mit ungültigen Handynummern. Es wurden keine Einträge erstellt.",
                  });
                  break;
                case StatusCode.EXCEL_IMPORT_TOO_RECENT_SINCE_LAST_IMPORT:
                  this.showToast({
                    life: 5000,
                    severity: "error",
                    summary: "Fehlgeschlagen",
                    detail: "Der Excel-Import wurde für heute bereits erfolgreich durchgeführt. Ein neuer Import kann morgen gestartet werden.",
                  });
                  break;
              }
              reject();
            });
          })
          .then(() => {
            this.showToast({
              life: 6000,
              severity: "success",
              summary: "Erfolgreich importiert",
              detail: `Es wurden erfolgreich ${this.listDto.list.length - count} neue Einträge importiert!`,
            });
          })
          .then(() => store.getSmsEntries(this.listDto));
      });
    },

    goToEdit(id: string) {
      this.$router.push("/controlpanel/sms-entries/edit/" + id);
    },

    goToCreate() {
      this.$router.push("/controlpanel/sms-entries/create");
    },
  },
  setup() {
    const store = useSmsEntryStore();
    const toast = useToast();

    const { filters, typeFilter } = storeToRefs(store);

    const listDto = ref(new SmsEntryListDto([]));

    const totalDtosCount = computed(() => listDto.value.totalRecordCount);
    const isLoading = computed(() => listDto.value.isLoading);

    const customError = ref("");

    const statusOptions = [
      {
        text: "kein Filter",
        value: SmsEntryStatus.NONE,
      },
      {
        text: "In Warteschlange",
        value: SmsEntryStatus.QUEUED,
      },
      {
        text: "Versendet",
        value: SmsEntryStatus.SENT,
      },
      {
        text: "Fehlgeschlagen",
        value: SmsEntryStatus.FAILED,
      },
    ] as DropdownOption<SmsEntryStatus>[];

    // make initial fetch.
    onMounted(() => {
      store.setFilter(listDto.value, filters.value.global.value, typeFilter.value);

      store.getSmsEntries(listDto.value);
    });

    const showToast = (toastOptions: ToastMessageOptions) => {
      toast.add(toastOptions);
    };

    return {
      listDto,
      totalDtosCount,
      isLoading,
      filters,
      typeFilter,
      statusOptions,
      SmsEntryStatus,
      Language,
      showToast,
      customError,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.p-datatable-header > div) {
  margin-bottom: 5px;
}
</style>
